import router from './router'
import getPageTitle from '@/utils/title'
import { hasToken } from '@/utils/auth'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from './store'
import { connectChat } from './chat';

import {ElMessage} from 'element-plus'

const LOGIN_PATH = '/login', DASHBOARD_PATH = '/dashboard', HOME_PATH = '/home'

const whitePathList = [LOGIN_PATH, HOME_PATH]
NProgress.configure({showSpinner: false})

let registerRouteFresh = true
router.beforeEach(()=> {
    NProgress.start()
})

router.beforeEach(async (to, form, next) => {
    document.title = getPageTitle(to.meta && to.meta.title || '')
    if (hasToken() &&  LOGIN_PATH === to.path) {
        next(DASHBOARD_PATH)
        return
    }
    // 白名单直接跳转
    if (whitePathList.indexOf(to.path) > -1) {
        next()
        return
    }

    // 没有token跳登录页
    if (!hasToken()) {
        next(LOGIN_PATH)
        return
    }

    const hasRoles = store.getters.roles && store.getters.roles.length > 0
    if (hasRoles) {
        next()
        return
    }

    try {
        // 检查token是否过期
        const { roles, routes } = await store.dispatch('auth/info')
        const accessRoutes = await store.dispatch('permission/generateRoutes', {roles, routes})
        accessRoutes.sort((a, b)=> a.meta.sort - b.meta.sort)
        accessRoutes.map(addRoute => {
            addRoute.meta.parent ? router.addRoute(addRoute.meta.parent, addRoute) : router.addRoute(addRoute)
        })
        connectChat()

        if (registerRouteFresh) {
            next(`/auth-redirect?redirect=${to.path}`)
            registerRouteFresh = false
            return
        }
    } catch (err) {
        await store.dispatch('auth/clear')
        ElMessage.error({ message: err || 'Has Error' })
        next(`${LOGIN_PATH}?redirect=${to.path}`)
        return
    }
    next()
})


router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
