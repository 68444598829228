<template>
  <el-config-provider :locale="locale">
    <sidebar :title="title" :routes="routes">
      <template #search>
        <header-search type="sidebar" />
      </template>
    </sidebar>
    <div class="relative md:ml-64 bg-slate-100">
      <navbar :title="title">
        <template #brand>
          <breadcrumb  home-path="/dashboard" />
        </template>
        <template #search>
          <header-search type="navbar" />
        </template>
      </navbar>
      <header-stats>
<!--
            <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
                <default-button @click="handleTest">重置权限</default-button>
            </div>-->
      </header-stats>
      <div class="px-4 md:px-10 mx-auto w-full" :style="{ marginTop: '-8.2rem' }">
        <router-view />
      </div>
    </div>
  </el-config-provider>
</template>
<script>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import { ElConfigProvider } from 'element-plus'
import { title } from '@/settings'
import { resetRouter } from '@/router'
import { mapState } from 'vuex'

import Navbar from './components/SubNavbar.vue'
import Sidebar from './components/SubSidebar.vue'
import HeaderStats from './components/Headers/HeaderStats'
import Breadcrumb from "@/layouts/components/SubBread.vue";
import HeaderSearch from "@/layouts/components/Headers/HeaderSearch.vue";

export default {
    components: {HeaderSearch, Breadcrumb, Navbar, Sidebar, HeaderStats, ElConfigProvider },
    computed: {
        ...mapState({
            routes: state => state.permission.addRoutes
        })
    },
    data() {
        return {
            locale: zhCn,
            title
        }
    },
    methods: {
        handleTest() {
            resetRouter()
        },
        getTitle() {
            return this.$route.meta.title || ''
        }
    }
}
</script>
