
export default {
    title: '用户管理',
    sort: 0,
    parent: 'root',
    routes: [
        {
            path: '/user-apply',
            component: () => import('@/views/user/UserApply'),
            meta: { title: '申请', icon: 'fa-clipboard-check' },
            hidden: true
        },
        {
            path: '/user-list',
            component: () => import('@/views/user/UserList'),
            meta: { title: '用户', icon: 'fa-user-doctor' },
        },
        {
            path: '/user-members',
            component: () => import('@/views/user/UserMembers'),
            meta: { title: '患者', icon: 'fa-user-injured' },
            hidden: true
        },
        {
            path: '/user-capsules',
            component: () => import('@/views/user/UserCapsules'),
            meta: { title: '诊断', icon: 'fa-syringe' },
            hidden: true
        }
    ]
}

