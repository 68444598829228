module.exports = {

    /**
     * @var {String}
     */
    title: '鹿升升',


    login: {
        /**
         * 登录类型 code: 手机验证码登录, password: 账号密码登录
         * @var {String}
         */
        type: 'password'
    },

    enableChat: false

}
