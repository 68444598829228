const state = {
  is_test: undefined
}

const mutations = {
  TEST: (state, is_test) => {
    state.is_test = is_test
  }
}

const actions = {
  test({ commit }, is_test) {
    commit('TEST', is_test)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
