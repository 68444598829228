import { enableChat as enable } from '@/settings'
export default {
    title: '测试',
    sort: 99,
    parent: 'root',
    routes: [
        {
            path: '/test-chat',
            component: () => import('@/views/chat/ChatRoom'),
            meta: { title: '测试', icon: 'fa-file' },
            hidden: !enable
        },
    ]
}

