/**
 *
 * @param {String} name
 * @param {String} separator
 * @param {Boolean} upper
 */
export function convertCamelCase(name, separator = '-', upper = true) {
    name = name.split(separator || '-')
        .reduce((prev, cur) => prev + cur.slice(0, 1).toUpperCase() + cur.slice(1))
    return upper ? name.slice(0, 1).toUpperCase() + name.slice(1)
        : name
}


export function deepClone(source) {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments .. deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach(keys => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys])
        } else {
            targetObj[keys] = source[keys]
        }
    })
    return targetObj
}

/**
 *
 * @param prefix
 * @returns {string}
 */
export function generateId( prefix = '') {
    return prefix + + new Date() + ((Math.random() * 1000).toFixed(0) + '')
}
