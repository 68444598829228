
export default {
    title: '权限&角色',
    sort: 1,
    parent: 'root',
    routes: [
        {
            path: '/admin',
            component: () => import('@/views/permission/AdminList.vue'),
            meta: { title: '账号', icon: 'fa-user-tie' }
        },
        {
            path: '/group',
            component: () => import('@/views/permission/GroupList.vue'),
            meta: { title: '分组', icon: 'fa-bookmark' }
        },
        {
            path: '/role',
            component: () => import('@/views/permission/RoleList.vue'),
            meta: { title: '角色', icon: 'fa-tags' }
        }
    ]
}

