import { getContacts, getRecords } from '@/api/chat'

const state = {
    cache: {
        connect: { isLogout: false, chatting: null },
        ws: null,
        to: { client: { id: '', name: '', uid: '' }},
        client: { list: [], name: '', id: '', contacts: [], total: 0 },
        records: { synced: false, map: {}, room: {}, private: {}, last: { room: {}, private: {} }},
        room: { id: 1, online_num: 0 }
    }
}
const mutations = {
    SET_CACHE: (state, { prop, value }) => {
        state.cache[prop] = value
    },
    SET_CONTACTS: (state, { contacts, total }) => {
        state.cache.client.contacts = contacts
        state.cache.client.total = total
        state.cache.connect.chatting && state.cache.connect.chatting.syncOnlineContacts()
    },
    SET_RECORD: (state, { record, is_top }) => {
        const { from_uid, to_uid } = record
        // record_id ||= null
        if (to_uid !== 'all' && to_uid) {
            if (to_uid === state.cache.client.uid) {
                if (!state.cache.records.private[from_uid]) {
                    state.cache.records.private[from_uid] = []
                }
                is_top ? state.cache.records.private[from_uid].unshift({ ...record })
                    : state.cache.records.private[from_uid].push({ ...record })

                // todo 最近记录 和 数量
                let num = typeof state.cache.records.last.private[from_uid] !== 'undefined'
                    ? state.cache.records.last.private[from_uid].num || 0
                    : 0
                // todo 计数添加
                if (state.cache.to.client.uid !== from_uid ) {
                    console.log('record_id =======> ', record.record_id, record.unread)
                    num ++
                } else {
                    // todo read 记录 from_uid
                    num = 0
                }
                // todo 最近记录
                state.cache.records.last.private[from_uid] = { ... record, num }

                return
            }
            if (!state.cache.records.private[to_uid]) {
                state.cache.records.private[to_uid] = []
            }
            is_top ? state.cache.records.private[to_uid].unshift({ ...record })
                : state.cache.records.private[to_uid].push({ ... record })
            return
        }

        if (!state.cache.records.room[state.cache.room.id]) {
            state.cache.records.room[state.cache.room.id] = []
        }

        is_top ? state.cache.records.room[state.cache.room.id].unshift({ ...record })
            : state.cache.records.room[state.cache.room.id].push({ ...record })
        state.cache.records.last.room[state.cache.room.id] = { ...record }
    },
    SET_SYNCED: (state, synced) => {
        state.cache.records.synced = synced
    },
    SYNC_ONLINE_CONTACTS: (state) => {
        state.cache.room.online_num = 0
        state.cache.client.contacts.length && state.cache.client.contacts.map((contact, idx) => {
            state.cache.client.list && (state.cache.client.contacts[idx].online
                = !!Object.values(state.cache.client.list).includes(contact.uid))
            state.cache.client.contacts[idx].online && state.cache.room.online_num ++
        })
    },
    SET_RECORDS_MAP: (state, map) => {
        Object.keys(map).map(key => {
            state.cache.records.map[key] = map[key]
        })
    }
}

const actions = {
    say({ commit }, { is_top, ... record }) {
        commit('SET_RECORD', { record, is_top })
        if (typeof record.record_id !== 'undefined') {
            commit('SET_RECORDS_MAP', { [record.record_id]: record })
        }
    },
    syncOlineContacts({ commit }) {
        commit('SYNC_ONLINE_CONTACTS')
    },
    getContacts({ commit, state }/**, { page, limit }**/) {
        return new Promise((resolve, reject) => {
            if (state.cache.client.contacts.length && state.cache.client.total) {
                resolve({
                    contacts: state.cache.client.contacts,
                    total: state.cache.client.total
                })
            } else {
                getContacts({ page: 1, limit: 100 }).then( response => {
                    const { data: { items, total }} = response
                    const contacts = []
                    items.map(({ uid, id, username, last_login_at }) => {
                        const online = Object.values(state.cache.client.list).includes(uid)
                        contacts.push({ uid, id, username, last_login_at, online })
                    })
                    commit('SET_CONTACTS', { contacts, total })
                    resolve({ contacts, total })
                }).catch(err => {
                    reject(err)
                })
            }
        })
    },
    getRecords({ commit, state }) {
        return new Promise((resolve, reject) => {
            state.cache.records.synced ? resolve(state.cache.records.map) : getRecords().then( response => {
                const { data: { items, total }} = response
                const map = {}
                if (!total) {
                    resolve(map)
                    return
                }
                items.sort((a, b) => {
                    return b.record_id - a.record_id
                }).map(({ record_id, unread, from_uid, content, time, to_uid }) => {
                    const from_client_name= state.cache.connect.chatting.getUsernameByUid(from_uid)
                    const record = { from_uid, from_client_name, content, time, to_uid, record_id, unread }
                    commit('SET_RECORD', { record, is_top: true })
                    map[record_id] = { ... record }
                })
                commit('SET_SYNCED', true)
                commit('SET_RECORDS_MAP', map)
                resolve(map)
            }).catch(err => {
                reject(err)
            })
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
