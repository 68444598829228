import { h } from 'vue'
import * as Icons from '@element-plus/icons-vue'
import { convertCamelCase } from '@/utils'

export default {
    props: {
        name: {
            type: String,
            default() {
                return 'edit'
            }
        }
    },
    render() {
        return h(Icons[convertCamelCase(this.name)])
    }
}
