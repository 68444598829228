<template>
  <div>
      <el-popover
        :width="192"
        trigger="click"
        popper-style="padding: 5px;"
        popper-class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 block"
      >
          <template #reference>
              <div class="items-center flex">
                <span class="w-12 h-12 text-sm text-white bg-slate-200 inline-flex items-center justify-center rounded-full">
                    <el-avatar :title="name" :size="48" src="">{{ name || '-' }}</el-avatar>
                </span>
              </div>
          </template>
          <template #default>
              <a
                href="javascript:void(0);"
                class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
                @click="setPassword"
              > {{ '修改密码' }} </a>
              <div class="h-0 my-2 border border-solid border-slate-100" />
              <a
                 href="javascript:void(0);"
                 class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
                 @click="handleLogout"
              > {{ '退出登录' }} </a>
          </template>
      </el-popover>


  </div>
</template>

<script>
import { ElAvatar, ElInput, ElPopover } from 'element-plus'

import image from '@/assets/img/profile/1.png';
import { h } from 'vue'
import { setPassword } from '@/api/auth'
import {mapState} from "vuex";

export default {
    components: {ElPopover, ElAvatar},
    computed: {
        ...mapState({
            name: state => state.auth.name
        })
    },
    data() {
        return {
            dropdownPopoverShow: false,
            image: image,
            form: { password: '', password2: '' }
        };
    },
    methods: {
        jumpTo(path) {
            this.$router.push({path})
        },
        handleLogout() {
            this.$store.dispatch('auth/logout').then(() => {
                this.jumpTo('/login')
            }).catch(err => {
                alert(err)
            })
        },
        setPassword() {
            this.form.password = ''
            this.form.password2 = ''
            this.$msgbox({
                title: `修改密码`,
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        if (!this.form.password) {
                            this.$message.warning({message: '请输入密码!'})
                            return;
                        }

                        if (this.form.password.length < 8) {
                            this.$message.warning({message: '密码长度不满足要求'})
                            return;
                        }

                        if (this.form.password !== this.form.password2 && !this.form.password2) {
                            this.$message.warning({message: '请再次输入密码!'})
                            return
                        }

                        if (this.form.password !== this.form.password2 && this.form.password2) {
                            this.$message.warning({message: '密码输入不一致'})
                            return
                        }

                        setPassword(this.form.password).then(({message}) => {
                            this.$message.success({message: '修改完成'})
                            console.log(message, this.form.password, this.form.password2)
                            done()
                        }).catch(err => {
                            this.$message.error({message: err})
                        })


                    } else {
                        this.$message.warning({message: '修改已取消'})
                        done()
                    }
                },
                message: () =>
                    h('div', {
                        class: ['py-3', 'px-3', 'mt-3'],
                    }, [
                        h(ElInput, {
                            placeholder: '请输入新密码',
                            modelValue: this.form.password,
                            type: 'password',
                            'onUpdate:modelValue': (val) => {
                                this.form.password = val
                            },
                        }),
                        h(ElInput, {
                            class: 'mt-5',
                            placeholder: '请再次输入密码',
                            modelValue: this.form.password2,
                            type: 'password',
                            'onUpdate:modelValue': (val) => {
                                this.form.password2 = val
                            },
                        }),
                    ])
            }).catch(() => '')
        }
    }
}
</script>
