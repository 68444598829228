<template>
    <el-popover
      width="192"
      popper-class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 block p-0"
    >
        <template #reference>
            <a class="text-slate-500 block py-1 px-3">
                <plus-icon icon-type="fa" icon="fa-bell" />
            </a>
        </template>
        <template #default>
            <a href="javascript:void(0);"
               class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            >Action</a>
            <a href="javascript:void(0);"
               class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            >Another action</a>
            <a href="javascript:void(0);"
               class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            >Something else here</a>
            <div class="h-0 my-2 border border-solid border-slate-100" />
            <a href="javascript:void(0);"
               class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-slate-700"
            >Seprated link</a>
        </template>
    </el-popover>
</template>
<script>
import { ElPopover } from 'element-plus'
import PlusIcon from "@/components/Element/Icons/PlusIcon.vue";

export default {
    components: {PlusIcon, ElPopover},
    data() {
      return {
        dropdownPopoverShow: false
      }
    }
}
</script>
