<template>
  <el-icon  v-if="iconType === 'element'" v-bind="$attrs" size="16px" style="vertical-align: middle">
      <element-icon :name="icon" />
  </el-icon>
  <fa-icon class="fa" v-if="iconType === 'fa'" :icon="[ iconCategory || 'fas', icon]" v-bind="$attrs" />
</template>
<script>
import { ElIcon } from 'element-plus'
import ElementIcon from './Element/Icon'
import FaIcon from './Fortawesome/Icon'

export default {
    components: { ElIcon, ElementIcon, FaIcon },
    props: {
        icon: {
            type: String,
            default() {
                return 'edit'
            }
        },
        iconType: {
            type: String,
            default() {
                return 'element'
            }
        },
        iconCategory: {
            type: String,
            default() {
                return ''
            }
        }
    }
}
</script>
<style scoped>

</style>
