<template>
    <li class="items-center">
        <router-link :to="itemPath" v-slot="{ href, navigate, isActive }">
            <a
               :href="href"
               class="text-xs uppercase py-3 font-bold block"
               :class="getLinkClasses(isActive)"
               @click="handleGo(navigate)"
            >
                <plus-icon
                    icon-type="fa"
                    :icon="itemIcon"
                    class="mr-2 text-sm"
                    :class="getIconClassObj(isActive)"
                /> {{ itemTitle }}
            </a>
        </router-link>
    </li>
</template>

<script>
import PlusIcon from '@/components/Element/Icons/PlusIcon'

export default {
    components: { PlusIcon },
    props: {
        itemTitle: {
            type: String,
            default() {
                return ''
            }
        },
        itemPath: {
            type: String,
            default() {
                return '/'
            }
        },
        itemIcon: {
            type: String,
            default() {
                return ''
            }
        }
    },
    methods: {
        getIconClassObj(isActive) {
            return {
                'opacity-75': isActive,
                'text-slate-300': !isActive,
                'w-4': true
            }
        },
        getLinkClasses(isActive) {
            return [isActive ? 'text-emerald-500 hover:text-emerald-600' : 'text-slate-700 hover:text-slate-500']
        },
        handleGo() {
            // navigate()
            this.$emit('onMenuItemGo')
        }
    }
}

</script>

<style scoped lang="scss">

</style>
