import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { ElMessageBox } from 'element-plus'

const AUTH_ERROR_CODES = [40003]

export const BASE_API = process.env.VUE_APP_BASE_API
export const PLATFORM_API = process.env.VUE_APP_PLATFORM_API

const service = axios.create({
    // url = base url + request url
    baseURL: BASE_API,
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000, // request timeout
    // pass http status
    validateStatus: (status) => {
        return status >= 200
    }
})

service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['X-Token'] = getToken()
        }
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
        return config
    },
    error => {
        // do something with request error
        console.error(error) // for debug
        return Promise.reject(error)
    }
)

service.interceptors.response.use(

    response => {
        const default_message = 'Unknown Error'
        const { message, msg, code } = response.data || { message: default_message, msg: default_message, code: -999 }
        // SUCCESS
        if (code === 20000 ) {
            return response.data
        }

        if (AUTH_ERROR_CODES.includes(parseInt(code))) {
            // to re-login
            ElMessageBox.confirm('您已注销,您可以取消以留在此页面上,或重新登录', '确认注销', {
                confirmButtonText: '重新登录',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                store.dispatch('auth/clear').then(() => {
                    location.reload()
                })
            }).catch(err => {
                console.log(err)
            })
        }

        // WARNING
        if (code === 10000 ) {
            return Promise.reject(message || msg || default_message)
        }

        // ERROR
        return Promise.reject(new Error( message || msg || default_message))
    },
    error => {
       return Promise.reject(error)
    }
)

export default service

