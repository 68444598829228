import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Layout from '@/layouts/AuthLayout'

export function getRouteName(path){
    return path && path.substring(1).replace(/\//g, '.')
}

export const constantRoutes = [
    // {
    //     path: '/redirect',
    //     component: Layout,
    //     hidden: true,
    //     name: 'redirect',
    //     children: [
    //         {
    //             path: '/redirect/:path(.*)',
    //             component: () => import('@/views/DefaultRedirect.vue')
    //         }
    //     ]
    // },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/AdminLogin.vue'),
        hidden: true,
    },
    {
        path: '/auth-redirect',
        name: 'auth-redirect',
        component: () => import('@/views/login/AuthRedirect'),
        hidden: true
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/DefaultLanding.vue'), //Landing,
        hidden: true
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/error-page/PageNoFind'),
        hidden: true
    },
    {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/NoPermission'),
        hidden: true
    },
    {
        path: '/',
        component: Layout,
        name: 'root',
        redirect: '/dashboard',
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                meta: { title: '首页' },
                component: () => import('@/views/dashboard/DashboardAdmin.vue')
            }
        ]
    },
    {
        path: '/profile',
        redirect: '/profile/index',
        name: 'profile',
        component: Layout,
        hidden: true,
        children: [
            {
                path: 'index',
                name: 'profile.index',
                meta: { title: '个人中心' },
                component: () => import('@/views/profile/AdminProfile.vue')
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'path',
        component: () => import('@/views/login/AuthRedirect')
    }
]

const modulesFiles = require.context('./modules', true, /\.js$/)
const moduleRoutes = []
modulesFiles.keys().reduce((modules, modulePath) => {
    const module = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    const { title: group, routes, sort, parent } = value.default
    routes && routes.map((route, idx) => {
        route.name = getRouteName(route.path)
        route.meta = { group, module, sort: parseInt(`${sort}${idx}`), parent: parent || '', ... route.meta }
        moduleRoutes.push(route)
    })
}, {})

export const asyncRoutes = [
    ... moduleRoutes
]

const router = createRouter({
    history: process.env.IS_ELECTRON ? createWebHashHistory(): createWebHistory(),
    routes: constantRoutes
});

export function resetRouter() {
    const whiteRoutes = []
    const getWhiteRoutes = (routes) => {
        routes.map(route => {
            route.children && getWhiteRoutes(route.children)
            route.name && !whiteRoutes.includes(route.name) && whiteRoutes.push(route.name)
        })
    }
    const removeRoutes = (routes) => {
        routes.map(route => {
            route.name && !whiteRoutes.includes(route.name) && router.removeRoute(route.name)
        })
    }
    getWhiteRoutes(constantRoutes)
    removeRoutes(router.getRoutes())
}

export default router
