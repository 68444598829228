<template>
  <form
    v-if="type === 'sidebar'"
    class="mt-6 mb-4 md:hidden"
  >
    <div class="mb-3 pt-0">
      <el-select
        ref="sidebarSearchSelect"
        v-model="search"
        :remote-method="querySearch"
        filterable
        default-first-option
        remote
        placeholder="搜索功能"
        class="sidebar-search-select w-full border-0 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none"
        @change="change"
      >
          <el-option v-for="item in options" :key="item.path" :value="item" :label="item.title.join(' > ')" />
      </el-select>
    </div>
  </form>
  <form
    v-else-if="type === 'navbar'"
    class="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3"
  >
    <div
      class="relative flex w-full flex-wrap items-stretch"
    >
      <span
        class="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
      >
        <plus-icon icon-type="fa" icon="fa-search" />
      </span>
      <el-select
        ref="headerSearchSelect"
        v-model="search"
        :remote-method="querySearch"
        filterable
        default-first-option
        remote
        placeholder="搜索功能"
        class="header-search-select w-full border-0 placeholder-slate-300 text-slate-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none"
        @change="change"
      >
        <el-option v-for="item in options" :key="item.path" :value="item" :label="item.title.join(' > ')" />
      </el-select>
    </div>
  </form>
</template>
<script>
import {ElSelect, ElOption} from 'element-plus'
import PlusIcon from "@/components/Element/Icons/PlusIcon.vue";

export default {
    components: {PlusIcon, ElSelect, ElOption},
    data() {
        return {
            search: '',
            options: [],
            searchPool: [],
            show: false,
            fuse: undefined
        }
    },
    props: {
        type: {
            type: String,
            default: ''
        }
    },
    methods: {
        querySearch() {
            console.log(arguments)
        },
        change() {
            console.log(arguments)
        }
    }
}
</script>
<style scoped lang="scss">
  .header-search-select {
    :deep(.el-input__wrapper) {
      @apply w-full bg-none border-0 outline-none focus:outline-none p-0;
    }
    :deep(.el-input__inner) {
      @apply border-0 px-3 py-3 placeholder-slate-300 text-sm text-slate-600 relative bg-white rounded shadow outline-none focus:outline-none focus:ring w-full pl-10;
      height: 44px;
    }
  }
  .sidebar-search-select {
    :deep(.el-input__wrapper) {
      @apply w-full bg-none border-0 outline-none focus:outline-none p-0;
    }
    :deep(.el-input__inner) {
      @apply border-0 px-3 py-2 h-12 border-solid border-slate-500 placeholder-slate-300 text-slate-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal;
      //height: 48px;
      @apply focus:border
    }
  }

</style>
