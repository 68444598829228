import request from '@/utils/request'

export function getOptionsGroup() {

    return request({
        url: '/options/group',
        method: 'get'
    })
}

export function getOptionsRole() {

    return request({
        url: '/options/role',
        method: 'get'
    })
}
