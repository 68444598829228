import { h } from 'vue'
import * as SolidIcons from '@fortawesome/free-solid-svg-icons'
import * as  BrandsIcons from '@fortawesome/free-brands-svg-icons'
import * as  RegularIcons from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { convertCamelCase } from '@/utils'

export default {
    components: { FontAwesomeIcon },
    props: {
        icon: {
            type: Array,
            default() {
                return ['fas', 'user-secret']
            }
        }
    },
    render(){
        let [type, name] = this.icon
        let icon;
        const key = convertCamelCase(name, '-', false);
        // let iconObj, iconName
        if(type === 'fas' && SolidIcons[key]) {
            icon = SolidIcons[key]
        } else if (type === 'fab' && BrandsIcons[key]) {
            icon = BrandsIcons[key]
        } else if(type === 'far'&& RegularIcons[key]) {
            icon = RegularIcons[key]
        } else {
            icon = SolidIcons.faUserSecret
            name = 'user-secret'
            type = 'fas'
        }
        library.add(icon)
        return h(FontAwesomeIcon, {
            icon: [type, name],
        })
    }
}
