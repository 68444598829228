import request from '@/utils/request'

export function getContacts(query) {
    return request({
        url: `/chat/contacts`,
        method: 'get',
        params: query
    })
}

export function getRecords() {
    return request({
        url: `/chat/records`,
        method: 'get'
    })
}
