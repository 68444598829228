import request from '@/utils/request'
export function login(data, type = 'code')
{
    return request({
        url: `/${type}-login`,
        method: 'post',
        data
    })
}

export function captcha()
{
    return request({
        url: '/captcha',
        method: 'get'
    })
}

export function sms(data)
{
    return request({
        url: '/sms',
        method: 'post',
        data
    })
}

export function info() {
    return request({
        url: '/admin/info',
        method: 'get'
    })
}

export function setPassword(data) {
    return request({
        url: '/admin/password',
        method: 'post',
        data
    })
}

