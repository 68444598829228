import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Chat from './chat'

import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'

import '@/assets/styles/tailwind/index.css'
import '@/assets/styles/index.scss'

import './permission'

createApp(App)
    .use(Chat)
    .use(store).use(router)
    .use(ElLoading).use(ElMessage).use(ElMessageBox)
    .mount('#app')
