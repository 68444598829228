
export default {
    title: '设置&配置',
    sort: 99,
    parent: 'root',
    routes: [
        {
            path: '/setting-home-index',
            component: () => import('@/views/setting_home/Index'),
            meta: { title: '首页设置', icon: 'fa-file' },
        },
        {
            path: '/app-version-index',
            component: () => import('@/views/settings/AppVersion'),
            meta: { title: '版本设置', icon: 'fa-file' },
        },
        {
            path: '/settings-agreement',
            component: () => import('@/views/settings/SettingsAgreement'),
            meta: { title: '协议', icon: 'fa-file' }
        },
        {
            path: '/settings-web-base',
            component: () => import('@/views/settings/SettingsWebBase'),
            meta: { title: 'Web端基本设置', icon: 'fa-file' }
        },
        {
            path: '/user-test-phone',
            component: () => import('@/views/userTestPhone/List'),
            meta: { title: '测试用户设置', icon: 'fa-user-doctor' }
        },
        {
            path: '/settings-medicine',
            component: () => import('@/views/settings/SettingsMedicine'),
            meta: { title: '中药', icon: 'fa-file' },
            hidden: true
        }
    ]
}

