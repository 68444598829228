<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <plus-icon icon-type="fa" icon="fa-bars" />
      </button>
      <!-- Brand -->
      <router-link
        class="md:block text-left md:pb-2 text-slate-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        to="/dashboard"
      >
        {{ title }}
      </router-link>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <notification-dropdown />
        </li>
        <li class="inline-block relative">
          <user-dropdown />
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-slate-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                class="md:block text-left md:pb-2 text-slate-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                to="/dashboard"
              >{{ title }}</router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <plus-icon icon-type="fa" icon="fa-times" />
              </button>
            </div>
          </div>
        </div>
        <slot name="search" />
        <!--
        <form class="mt-6 mb-4 md:hidden">
          <div class="mb-3 pt-0">
            <input
              type="text"
              placeholder="Search"
              class="border-0 px-3 py-2 h-12 border border-solid border-slate-500 placeholder-slate-300 text-slate-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
            />
          </div>
        </form>
Form -->
        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
<!--        <h6-->
<!--          class="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"-->
<!--        >-->
<!--          {{ '首页' }}-->
<!--        </h6>-->
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <menu-item item-icon="fa-gauge" item-path="/dashboard" item-title="首页" @onMenuItemGo="handleGo"/>
        </ul>
        <hr class="my-4 md:min-w-full" />
        <template :key="`group-${i}`" v-for="(group, i) in menus">
            <template v-if="!group.hidden">
              <h6 class="md:min-w-full text-slate-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                  {{ group.title }}
              </h6>
              <ul class="md:flex-col md:min-w-full flex flex-col list-none">
                  <template
                    v-for="(menu, i) in group.children"
                    :key="`menu-${i}`"
                  >
                    <menu-item
                        v-if="!menu.hidden"
                        :item-icon="menu.icon"
                        :item-path="menu.path"
                        :item-title="menu.title"
                        @onMenuItemGo="handleGo"
                    />
                  </template>
              </ul>
              <hr v-if="menus.length !== i+1" class="my-4 md:min-w-full" />
            </template>
        </template>
      </div>
    </div>
  </nav>
</template>
<script>
import NotificationDropdown from './Dropdown/NotificationDropdown'
import UserDropdown from './Dropdown/UserDropdown'
import MenuItem from './Sidebar/MenuItem'
import PlusIcon from "@/components/Element/Icons/PlusIcon.vue";


export default {
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        routes: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    mounted() {
      this.generateMenus()
    },
    data() {
        return {
            collapseShow: 'hidden',
            menus: []
        };
    },
    methods: {
        toggleCollapseShow(classes) {
            this.collapseShow = classes;
        },
        generateMenus() {
            const map = {}
            this.routes.map(({ path, meta: {title, icon, group}, hidden }) => {
                if (!hidden){
                    if (!Object.hasOwnProperty.call(map, group)) {
                        map[group] = this.menus.length
                        this.menus.push({ title: group, children: []})
                    }

                    this.menus[map[group]].children.push({ title, path, icon })
                }
            })
        },
        handleGo() {
            this.toggleCollapseShow('hidden')
        }
    },
    components: {PlusIcon, MenuItem, NotificationDropdown, UserDropdown},
};
</script>
