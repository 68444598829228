<template>
    <router-link to="/" v-slot="{ href/**, navigate, isActive **/}">
        <a class="text-white text-sm uppercase hidden lg:inline-block font-semibold" :href="href">{{ '首页' }}</a>
    </router-link>
    <router-link v-if="!isHome()" :to="getPath()" v-slot="{ href/**, navigate, isActive **/}">
        <span class="text-white text-sm uppercase hidden lg:inline-block font-semibold px-3 ">/</span>
        <a class="text-white text-sm uppercase hidden lg:inline-block font-semibold" :href="href">{{ getTitle() }}</a>
    </router-link>
</template>
<script>
export default {
    props: {
        homePath: {
            type: String,
            default() {
                return '/dashboard'
            }
        }
    },
    methods: {
        getTitle() {
            if (!this.$route.meta) return ''
            return this.$route.meta.title || ''
        },
        isHome() {
            return this.getPath() === this.homePath
        },
        getPath() {
            return this.$route.path || ''
        }
    }
}



</script>
<style scoped lang="scss">

</style>
