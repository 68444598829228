import { w3cwebsocket as W3cWebSocket } from 'websocket'
import store from './store'
import { ElMessage } from 'element-plus'
import md5 from 'md5'
import { enableChat as enable } from './settings'

const MAX_TRY_TIMES = 6
let constructor_times = 0
class Chat {

    constructor(url, store) {
        this.WEB_SOCKET_URL = url
        this.$store = store
        this.module = 'backend'
        this.try_times = 0
        this.init()
    }

    init() {
        this.props = new Proxy(this.$store, {
            set: (target, prop, value) => {
                // console.log(`Setting ${prop} to ${value}`)
                target[prop] = value
                target.commit('chat/SET_CACHE', { prop, value })
                return true
            },
            get: (target, prop) => {
                // console.log(`Getting ${prop}`)
                return target.getters.chat[prop]
            },
        })
        constructor_times ++;
        console.log(constructor_times)
        this.events = {}
        this.on('unknown', (...args) => {
            console.log('unknown', '=====>', args)
        })
        this.cache.connect.chatting = this
    }

    get cache() {
        return this.props;
    }
    set cache(data) {
        Object.assign(this.props, data);
    }

    emit(event, ... args) {
        if (!this.events[event]) {
            this.events[event] = []
        }
        if (this.events[event].length > 0) {
            this.events[event].map(callback => {
                callback(... args)
            })
        }
    }
    on(event, callback) {
        if (!this.events[event]) {
            this.events[event] = []
        }
        if (-1 === this.events[event].indexOf(callback)) {
            this.events[event].push(callback)
        }
    }

    off(event, callback){
        if (!this.events[event]) {
            this.events[event] = []
        }
        const idx = this.events[event].indexOf(callback);
        if (-1 !== idx) {
            delete this.events[event][idx]
        }
    }

    async connect() {
        if (enable) {
            !this.WEB_SOCKET_URL.includes('wss://') && (this.WEB_SOCKET_URL =`wss://${document.domain}${this.WEB_SOCKET_URL}`)
            this.cache.ws = new W3cWebSocket(this.WEB_SOCKET_URL)
            this.cache.connect.isLogout = false
            // 当socket连接打开时，输入用户名
            this.cache.ws.onopen = (...args) => {
                this.onopen(...args)
            }
            // 当有消息时根据消息类型显示不同信息
            this.cache.ws.onmessage = ({data}) => {
                this.onmessage(data)
            }
            // 关闭事件
            this.cache.ws.onclose = (... args) => {
                this.emit('close', this.cache.connect.isLogout, ... args)
                !this.cache.connect.isLogout && ElMessage.error(this.try_times < MAX_TRY_TIMES ? `websocket: 出现错误, 3s后重试`: 'websocket: 达到最大尝试次数')
                if (!this.cache.connect.isLogout && this.try_times < MAX_TRY_TIMES) {
                    console.log('连接关闭，定时重连')
                    setTimeout(() => {
                        this.try_times ++
                        this.connect()
                    }, 3000)
                }else {
                    console.log('连接关闭')
                }
            }
            this.cache.ws.onerror = (... args) => {
                this.emit('error', ... args)
                console.log('出现错误')
            }
            this.token = this.$store.getters.token
            this.emit('connect', { url: this.WEB_SOCKET_URL })
            await this.$store.dispatch('chat/getContacts', { page: 1, limit: 10 })
            await this.$store.dispatch('chat/getRecords')
        }
    }
    onopen(... args) {
        this.emit('open', ...args)
        if (!this.cache.client.name) {
            this.cache.client.name = md5(`${this.$store.getters.id}`)
            this.cache.client.uid = md5(`${this.$store.getters.id}`)
        }
        // 登录
        const data = {
            type: 'login',
            client_name: this.cache.client.name,
            uid: this.cache.client.uid,
            room_id: this.cache.room.id
        }

        console.log('websocket握手成功，发送登录数据:' + JSON.stringify(data));
        // this.cache.ws.send(JSON.stringify(data));
        this.send(data)
    }

    send(data) {
        data.token = this.token
        data.module = this.module
        this.cache.ws.send(JSON.stringify(data))
    }
    onmessage(data) {
        const { type, client_name, client_list, clients, uid, client_id, time, from_uid, from_client_name,
            from_client_id, content, to_uid, record_id } = JSON.parse(data)
        let hasLogin, login_content, r_form_uid

        switch (type) {
            case 'ping':
                this.emit('ping', { type })
                this.send({ type: 'pong' })
                break
            case 'login':
                hasLogin = Object.values(this.cache.client.list || {}).includes(uid)
                if (client_list) {
                    this.cache.client.uid = uid
                    this.cache.client.list = clients
                } else {
                    this.cache.client.list[client_id] = uid
                }
                this.syncOnlineContacts()
                login_content = `${ uid === this.cache.client.uid ? '你' : this.getUsernameByUid(uid)} 加入了聊天室`
                !hasLogin && this.say(uid, this.getUsernameByUid(uid) || this.$store.getters.name, login_content, time)
                this.emit('login', { uid, client_list, time, client_name, username: this.getUsernameByUid(uid)  })
                console.log(client_name + '登录成功')
                break
            case 'say':
                this.emit('say', { from_uid, from_client_name, from_username: this.getUsernameByUid(from_uid), content, time, to_uid })
                this.say(from_uid, this.getUsernameByUid(from_uid), content, time, to_uid, record_id)
                break
            case 'logout':
                r_form_uid =  this.cache.client.list[from_client_id]
                console.log(r_form_uid, r_form_uid, this.getUsernameByUid(r_form_uid))
                delete this.cache.client.list[from_client_id]
                this.syncOnlineContacts()
                if(!Object.values(this.cache.client.list).includes(r_form_uid)) {
                   this.say(from_uid, this.getUsernameByUid(r_form_uid), this.getUsernameByUid(r_form_uid) + ' 退出了', time)
                }
                this.emit('logout', { from_client_id, from_uid, from_client_name, from_username: this.getUsernameByUid(r_form_uid), content, time, to_uid })
                break
            case 'unauthorized':
                this.emit('unauthorized', { content, time })
                this.cache.connect.isLogout = true
                ElMessage.error(`websocket: ${content}`)
                break
            default:
                this.emit('unknown', JSON.parse(data))
                break
        }
    }

    syncOnlineContacts() {
        this.$store.dispatch('chat/syncOlineContacts')
    }
    say(from_uid, from_client_name, content, time, to_uid, record_id) {
        this.$store.dispatch('chat/say', { from_uid, from_client_name, content, time, to_uid, record_id, is_top: false })
    }

    submit(content) {
        if (enable) {
            this.send({
                type: 'say',
                to_uid: this.cache.to.client.uid || 'all',
                to_client_name: this.cache.to.client.uid || '',
                content
            })
        }
    }

    getUsernameByUid(uid) {
        let username = ''
        // console.log(this.cache.client.contacts, uid)
        this.cache.client.contacts.map(({username: contact_username, uid: contact_uid }) => {
            uid === contact_uid && (username = contact_username)
            // console.log( uid === contact_uid, '===========', username, contact_username, uid,contact_uid )
        })
        return username
    }
    readRecords(record_ids) {
        console.log(record_ids)
    }
    readPrivateRecords(uid) {
        this.send({
            type: 'read',
            read_uid: uid,
            to_client_name: this.cache.to.client.uid || ''
        })
    }
    changeReceiver(uid) {
        this.cache.to.client.uid = !uid ? '' : uid
        this.cache.to.client.name = this.getUsernameByUid(uid)
        let num = typeof this.cache.records.last.private[uid] === 'undefined'
            ? 0
            :  this.cache.records.last.private[uid].num || 0
        if (num) {
            this.cache.records.last.private[uid].num = 0
        }
    }
    close() {
        if (this.cache.ws.readyState === 1) {
            this.cache.connect.isLogout = true
            this.cache.ws.close()
        }
    }
}
// let chatting = {}
export function connectChat() {
    if (enable) {
        const chatting = store.getters.chat.connect.chatting
        chatting.connect && chatting.connect()
    }
}

export function closeChat() {
    if (enable) {
        const chatting = store.getters.chat.connect.chatting
        chatting.close && chatting.close()
    }
}

export default {
    install(app) {
        app.config.globalProperties.$chat = new Chat(process.env.VUE_APP_WS_URL, store)
    }
}

