import { getOptionsRole, getOptionsGroup } from '@/api/options'

const getOptions = (map) => {
    const options = [];
    Object.keys(map).map( key => {
        options.push({ label: map[key]['name'], value: parseInt(key) })
    })
    return options
}

const state = {
    group: {
        map: undefined
    },
    role: {
        map: undefined
    }

}

const mutations = {
    SET_GROUP_MAP(state, map) {
        state.group.map = map
    },
    SET_ROLE_MAP(state, map) {
        state.role.map = map
    }
}

const actions = {
    getRoleOptions({ commit, state }, online) {
        return new Promise((resolve, reject) => {
            if (online || typeof state.role.map === 'undefined') {
                getOptionsRole().then(({ data: { map }}) => {
                    commit('SET_ROLE_MAP', map)
                    const options = getOptions(map)
                    resolve({ options, map })
                }).catch(err=>reject(err))
            }else {
                const map = state.role.map
                const options = getOptions(map)
                resolve({ map, options })
            }
        })
    },
    getGroupOptions({ commit, state }, online) {
        return new Promise((resolve, reject) => {
            if (online || typeof state.group.map === 'undefined') {
                getOptionsGroup().then(({ data: { map }}) => {
                    commit('SET_GROUP_MAP', map)
                    const options = getOptions(map)
                    resolve({ map, options })
                }).catch(err=>reject(err))
            }else {
                const map = state.group.map
                const options = getOptions(map)
                resolve({ map, options })
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
