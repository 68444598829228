const CacheKey = 'platform-info'

export function getInfo()
{
    return JSON.parse(localStorage.getItem(CacheKey) ?? '{"host":"","code":""}')
}

export function hasInfo()
{
    return !!localStorage.getItem(CacheKey)
}

export function setInfo(info)
{
    return localStorage.setItem(CacheKey, JSON.stringify(info))
}

export function removeInfo()
{
    return localStorage.removeItem(CacheKey)
}
